import kebabCase from 'lodash/kebabCase'
import React from "react"
import { Link, graphql } from "gatsby"
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Sidebar from '../components/Sidebar'
import '../../src/assets/scss/pages/_products.scss'

class ProductsPage extends React.Component {
    render() {
        const edges = this.props.data.allShopifyProduct.edges
        const { title } = this.props.data.site.siteMetadata

        return (
            <Layout>
              <div>
                <Helmet title={`All Produtcs - ${title}`} />
                <div className="content">
                  <div className="content__inner">
                    <div className="page">
                      <h1 className="page__title">プロダクト</h1>
                      <div className="page__body">
                        <div className="products">
                          <ul className="products__list">
                            
                            {edges.map(({ node }) => (
                                <li key={node.shopifyId}>
                                  <Link className="products__title" to={`/product/${kebabCase(node.handle)}`}>
                                    <img src={node.images[0].originalSrc} alt={node.handle} />
                                  </Link>
                                  <h3>
                                    <Link className="products__title" to={`/product/${kebabCase(node.handle)}`}>
                                      {node.title}
                                    </Link>
                                    <Link className="products__title" to={`/product/${kebabCase(node.handle)}`}>
                                      {" - "}€{node.priceRange.minVariantPrice.amount}
                                    </Link>
                                  </h3>
                                </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Sidebar {...this.props} />
              </div>
            </Layout>
        )
    }
}

export default ProductsPage

export const pageQuery = graphql`
  {
    allShopifyProduct(sort: { fields: [title] }) {
      edges {
        node {
          title
          shopifyId
          description
          handle
          images {
            originalSrc
          }
          priceRange {
            minVariantPrice {
              amount
            }
          }
        }
      }
    }
    site {
        siteMetadata {
          title
          subtitle
          copyright
          menu {
            label
            path
          }
          author {
            name
            #email
            #telegram
            twitter
            github
            #rss
            #vk
          }
        }
      }
  }
`